<template>
    <div ref="signatureCanvasWrap" class="px-4 py-9">
        <div class="w-100 flex flex-row justify-content-center">
            <Scrollable :center="true">
                <tabs-component :tabHeadings="signatureOptions" :selectedTabIndex="selectedOptionIndex"
                    tabTitleProperty="title" @changed="selectOption" />
            </Scrollable>
        </div>

        <canvas height="250" ref="signatureCanvas" @mousedown.passive="mousedown" @mousemove.passive="mousemove"
            @mouseup.passive="mouseup" @touchstart.passive="touchstart" @touchmove.passive="touchmove"
            @touchend.passive="touchend"
            :style="`touch-action:none;margin-top:30px;display: ${this.selectedOption == 'write' ? 'auto' : 'none'}`" />

        <div class="mt-8 flex flex-start items-start" v-if="this.selectedOption == 'type'"
            style="max-width: 300px; width: min(100%, 300px);align-items: flex-start;">
            <label for="typedInputField" style="font-size: 1rem;margin-left:35px;">Signature:</label>
            <input autocomplete=off ref="typedInputField" @input="ApplyText($event.target.value)" type="text"
                placeholder="Type the signature here" @keyup.enter="EmitEnter"
                class="form-control mx-auto mt-4 p-4 text-lg focus:ring-0 focus:outline-none" />
        </div>
        <div class="flex-col gap-4 justify-content-center h-auto mt-4" v-if="this.selectedOption != 'remote'">
            <div id="btn-wrap" class="flex sm:flex-row flex-col-reverse gap-3 justify-center">
                <button class="btn btn-destructive flex items-center gap-2 justify-center w-full sm:w-auto"
                    @click="EraseSignature()">Clear
                </button>
                <button class="btn btn-primary w-full sm:w-auto" @click="GetSignature()">Save Signature</button>
            </div>
            <i class="font-weight-400 mb-4" v-html="this.bindingLegalText"></i>
        </div>
        <div class="flex gap-4 justify-content-center h-auto mt-5 mb-10"
            v-if="this.allowRemoteSigning && this.selectedOption == 'remote'">
            <div class="w-75" style="margin-bottom: -1.5px!important;" v-if="this.signatureAttribution">
                What is the {{ signatureAttribution }}'s email address to receive and sign documents?
            </div>
            <input autocomplete=off ref="signerContact" v-model="this.signerContact" type="text"
                placeholder="Email of Signer" :disabled="(parseInt(this.remoteSignInterval))" :aria-label="`What is the
                email address to collect the ${signatureAttribution}'s signature?`"
                @input="(e) => { e.target.classList.remove('is-invalid'); this.ValidRemoteContact = this.IsValidEmail(e.target.value) }"
                @blur="(e) => { if (e.target.value && !this.IsValidEmail(e.target.value)) { e.target.classList.add('is-invalid'); } }"
                @change="(event) => this.$emit('remoteSignContactUpdate', event.target.value)"
                class="form-control mx-auto mt-0" style="max-width: 300px; width: min(100%, 300px);"
                @keydown.enter="FetchSignature()" />
            <div class="col-sm-4 col-12">
                <button class="btn btn-primary w-100 w-sm-fit" :disabled="!this.ValidRemoteContact"
                    v-if="!this.remoteSignInterval" @click="FetchSignature()">
                    Request Signature
                </button>
                <div v-else class="d-flex flex-col gap-4">
                    <DisabledProgressButton width="" />
                    <a style="cursor:pointer;" class="user-select-none" @click="this.CancelSignature()">Cancel
                        Request</a>
                </div>

            </div>

            <GuidanceBox v-if="this.remoteSignInterval && this.secretKey" heading="Secret Access Key"
                :calloutText="this.secretKey">
                Please provide the signer this secret access key:
            </GuidanceBox>

            <p v-if="this.remoteSignInterval" class="w-75">
                A signature request email has been sent from <code>notifications@rapidsign.net</code> to
                <code v-text="this.signerContact"></code>.
                <br><br>
                This may take a few minutes. If you don't see the email, please check your spam/junk folder.
                <br>
                The signature will appear here automatically once it has been collected. Please wait.

            </p>

        </div>

    </div>
</template>

<script>
import DisabledProgressButton from "./DisabledProgressButton.vue";
import axios from "axios";
import GuidanceBox from "./GuidanceBox.vue";
import TabsComponent from "./TabsComponent.vue";
import Scrollable from "./Scrollable.vue";
export default {
    name: 'SignatureCanvas',
    components: { DisabledProgressButton, GuidanceBox, TabsComponent, Scrollable },
    emits: ['signaturePathUpdated', 'remoteSignContactUpdate', 'remoteSignIntervalUpdate', 'signed', 'deleteRemoteSignKeyValue', 'signatureMetadata', 'EmitEnter'],
    props: {
        typedSignatureDefault: {
            type: Boolean,
            default: false
        },
        bindingLegalText: {
            type: String,
            default: 'By pressing <code>Save Signature</code>, you are signing this document electronically. You agree that your electronic signature is the legal equivalent of my manual/handwritten signature on this document and you consent to its use on this document.',
        },
        signId: {
            type: String,
            required: true,
        },
        sessionId: {
            type: String,
            required: true,
        },
        signatureAttribution: {
            type: String,
        },
        selectedSignaturePath: {
            type: String,
            default: 'type', // should be 'type' or 'write' or 'remote'
        },
        allowRemoteSigning: {
            type: Boolean,
            default: false,
        },
        remoteSignContact: {
            type: String,
            default: '',
        },
        remoteSignInterval: {
            default: false,
        },
        secretKey: {
            type: String,
            default: '',
        },
        isaApi: {
            type: String,
            required: true,
        }
    },
    computed: {
        selectedOptionIndex() {
            // see if nothing is selected, if that's the case select the typed or written option (prefer typed based on typedSignatureDefault)
            return this.signatureOptions.findIndex(option => option.value === this.selectedOption);
        }
    },
    data() {
        return {
            ctx: null,
            sign: false,
            x: 0,
            y: 0,
            signatureImageBase64: '',
            drawnPixelsVarianceHighX: 0,
            drawnPixelsVarianceLowX: 0,
            drawnPixelsVarianceHighY: 0,
            drawnPixelsVarianceLowY: 0,
            typedSignature: this.typedSignatureDefault ? '' : false,
            showTypeOption: this.selectedSignaturePath === 'type',
            selectedOption: this.selectedSignaturePath,
            interval: false,
            signerContact: this.remoteSignContact,
            ValidRemoteContact: false,
            isDrawing: false,
            debouncedUpdateCanvasSize: null,
            signatureOptions: [
                { title: 'Write Signature', value: 'write' },
                { title: 'Type Signature', value: 'type' },
                this.allowRemoteSigning ? { title: 'Remote Signature', value: 'remote' } : null
            ].filter(a => a),
        }
    },
    methods: {
        EmitEnter() {
            this.$emit('EmitEnter');
        },
        selectOption(index) {
            const newOption = this.signatureOptions[index].value;
            if (this.selectedOption !== newOption) {
                this.selectedOption = newOption;
                this.showTypeOption = newOption === 'type';
                this.$emit('signaturePathUpdated', newOption);
                this.EraseSignature();
            }
        },
        // Function to apply text to the canvas
        ApplyText(text) {
            if (this.showTypeOption == false) {
                return;
            }
            this.typedSignature = text;
            const canvas = this.$refs.signatureCanvas;
            const ctx = canvas.getContext('2d');
            let font_size = 48; // Starting font size
            const font_family = 'Smooch'; // Placeholder for customizable font

            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

            // Adjust font size to fit text in canvas
            do {
                ctx.font = `${font_size}px ${font_family}`;
                font_size--;
            } while (ctx.measureText(text).width > canvas.width && font_size > 1);

            ctx.fillText(text, 10, canvas.height / 2); // Apply text
        },
        IsValidEmail(value) {

            return ((value.split('@').length - 1) == 1 && (value.toLowerCase().match(/^\S+@\S+\.\S+$/) !== null))
        },
        IsValidSignature() {
            if (!this.showTypeOption || `${this.typedSignature}`.trim().length < 6) {
                if (this.drawnPixelsVarianceHighX - this.drawnPixelsVarianceLowX < 100) {
                    return false;
                }
            }

            if (!this.showTypeOption || `${this.typedSignature}`.trim().length < 6) {
                if (this.drawnPixelsVarianceHighY - this.drawnPixelsVarianceLowY < 100) {
                    return false;
                }
            }

            if (this.typedSignature !== false) {
                if (`${this.typedSignature}`.trim().length >= 6) {
                    return true;
                } else {
                    return false;
                }
            }

            return true;
        },
        ShowError() {
            alert('Please make sure that you have signed the signature pad.  If you have signed the signature pad, please make your signature bigger.');
        },
        GetSignature() {
            this.sign = false;
            if (this.IsValidSignature()) {
                this.signatureImageBase64 = this.$refs.signatureCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                this.$emit('signed', this.signatureImageBase64);
            } else {
                this.ShowError();
                this.EraseSignature();
                return;
            }
        },
        mousedown($event) {
            this.sign = true;
            this.x = $event.offsetX;
            this.y = $event.offsetY;
            this.isDrawing = true;
        },
        mousemove($event) {
            if (this.sign) {
                this.drawLoop($event.offsetX, $event.offsetY);
            }
        },
        mouseup() {
            this.sign = false;
            this.isDrawing = false;
            this.x = 0;
            this.y = 0;
        },
        drawLoop(newX, newY) {
            if (this.isDrawing) {
                this.draw(this.x, this.y, newX, newY);
                this.updateDrawnPixelsVariance(newX, newY);
                this.x = newX; // Update x to newX
                this.y = newY; // Update y to newY
            }
        },
        draw(x1, y1, x2, y2) {
            this.ctx.beginPath();
            this.ctx.lineWidth = 4;
            this.ctx.lineCap = 'round'; // Smooth line endings
            this.ctx.lineJoin = 'round'; // Smooth line joins
            this.ctx.moveTo(x1, y1);
            this.ctx.lineTo(x2, y2);
            this.ctx.stroke();
            this.ctx.closePath();
        },
        updateCanvasSize() {
            try {
                const canvasWrap = this.$refs.signatureCanvasWrap;

                const canvas = this.$refs.signatureCanvas;
                canvas.width = canvasWrap.clientWidth;
                this.ctx.clearRect(0, 0, canvas.width, canvas.height);
            } catch (e) {
                // Do nothing
            }
        },
        touchstart(event) {
            event.preventDefault(); // Prevent scrolling and other default touch actions
            if (event.touches.length > 0) {
                this.sign = true;
                const newX = event.touches[0].clientX - event.target.getBoundingClientRect().left;
                const newY = event.touches[0].clientY - event.target.getBoundingClientRect().top;
                this.x = newX;
                this.y = newY;
                this.isDrawing = true;
            }
        },
        touchmove(event) {
            event.preventDefault(); // Prevent scrolling and other default touch actions
            if (this.sign && event.touches.length > 0) {
                const newX = event.touches[0].clientX - event.target.getBoundingClientRect().left;
                const newY = event.touches[0].clientY - event.target.getBoundingClientRect().top;
                this.drawLoop(newX, newY);
            }
        },
        touchend(event) {
            event.preventDefault(); // Prevent scrolling and other default touch actions
            this.sign = false;
            this.isDrawing = false;
            this.x = 0;
            this.y = 0;
        },
        EraseSignature() {
            this.sign = false;
            this.typedSignature = false;
            const canvas = this.$refs.signatureCanvas;
            this.ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.signatureImageBase64 = '';
            this.drawnPixelsVarianceHighX = 0;
            this.drawnPixelsVarianceLowX = 0;
            this.drawnPixelsVarianceHighY = 0;
            this.drawnPixelsVarianceLowY = 0;

            this.isDrawing = false;
            this.sign = false;
            this.prevX = 0; // Reset previous X coordinate
            this.prevY = 0; // Reset previous Y coordinate



            if (this.$refs.typedInputField)
                this.$refs.typedInputField.value = '';

            if (this.showTypeOption == true) {
                if (this.$refs.typedInputField)
                    this.$refs.typedInputField.focus();
            }
            if (this.remoteSignInterval) {
                clearInterval(this.remoteSignInterval);
                this.$emit('remoteSignIntervalUpdate', false);
            }
        },
        async NotifySigner() {
            const endpoint = 'https://api.rapidsign.net/pdf/notify-signer';

            let headersOrig = [];
            const callback = () => {
            };

            let newHeaders = []
            for (const headerElement of headersOrig) {
                let header = { ...headerElement }
                if (header.type == 'answer' && header.value) {
                    header.value = await this.GetAnswer(header.value);
                }
                delete (header.type);
                newHeaders.push(header);
            }
            let headers = newHeaders.filter(a => a.key);
            headers = headers.filter(a => a.key != 'session_id').filter(a => a.key != 'authorization');
            headers.push({
                key: 'Authorization',
                value: this.isaApi,
            });

            let body = [
                { 'key': 'to', 'value': this.signerContact },
                { 'key': 'signId', 'value': this.signId },
                { 'key': 'sessionId', 'value': this.sessionId },
                { 'key': 'bindingLegalText', 'value': this.bindingLegalText },
            ]


            headers.forEach((header) => {
                axios.defaults.headers.common[header.key] = header.value;
            });
            axios.defaults.withCredentials = false;
            axios.defaults.headers.common['Content-Type'] = 'application/json';

            let bodyParams = {};
            body.forEach((bdy) => {
                bodyParams[bdy.key] = bdy.value;
            });

            axios.post(endpoint, { ...bodyParams }).then(axiosResponse => {
                let data = axiosResponse.data;
                data['_status'] = axiosResponse.status;
                data['_response'] = axiosResponse;

            }).catch(error => {

                this.processing = 3;
                let axiosResponse = error?.request;
                let data = { ...axiosResponse };
                data['_status'] = axiosResponse?.status;
                data['_response'] = axiosResponse;

                eval(callback)(data);
            });


        },
        CancelSignature() {

            clearInterval(this.remoteSignInterval);
            this.$emit('remoteSignIntervalUpdate', null);
            this.$emit('remoteSignContact', '');
            this.$emit('deleteRemoteSignKeyValue');
            this.signerContact = '';

            // focus in signerContact
            this.$nextTick(() => {
                this.$refs.signerContact.focus();
            });

        },

        FetchSignature() {

            this.NotifySigner();

            // Every 5 seconds, make a request to the lookup url
            const url = `https://api.rapidsign.net/pdf/retrieve-signature?sessionId=${encodeURIComponent(this.sessionId)}&signId=${encodeURIComponent(this.signId)}`
            // fetch the url and then, if there's a json response with a `signature` key, set the signature to that value
            let interval = setInterval(() => {
                fetch(url)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data?.signature) {
                            if (this.remoteSignInterval) {
                                clearInterval(this.remoteSignInterval);
                                this.$emit('remoteSignIntervalUpdate', null);
                                this.signatureImageBase64 = data.signature;
                                this.$emit('signed', this.signatureImageBase64);
                                try {
                                    this.userMetadata = JSON.parse(data.userMetadata);
                                    this.$emit('signatureMetadata', this.userMetadata);
                                } catch (e) { /* do nothing */ }
                            }
                        }
                    })
                    .catch(() => { });
            }, 5000);
            this.$emit('remoteSignIntervalUpdate', interval);



        },
        updateDrawnPixelsVariance(currX, currY) {
            if (this.drawnPixelsVarianceHighX < currX || this.drawnPixelsVarianceHighX == 0) {
                this.drawnPixelsVarianceHighX = currX;
            }
            if (this.drawnPixelsVarianceLowX > currX || this.drawnPixelsVarianceLowX == 0) {
                this.drawnPixelsVarianceLowX = currX;
            }
            if (this.drawnPixelsVarianceHighY < currY || this.drawnPixelsVarianceHighY == 0) {
                this.drawnPixelsVarianceHighY = currY;
            }
            if (this.drawnPixelsVarianceLowY > currY || this.drawnPixelsVarianceLowY == 0) {
                this.drawnPixelsVarianceLowY = currY;
            }
        },
    },
    watch: {
        modelValue(model) {
            if (!model) {
                this.ctx.clearRect(0, 0, this.$refs.signatureCanvas.width, this.$refs.signatureCanvas.height)
            }
        }
    },
    mounted() {
        this.ctx = this.$refs.signatureCanvas.getContext('2d');
        this.ctx.strokeStyle = 'black';
        this.ctx.lineWidth = 4; // Adjust line width as needed
        this.ctx.lineCap = 'round'; // Smooth line endings
        this.ctx.lineJoin = 'round'; // Smooth line joins

        // Call the method on mount and add resize event listener
        this.updateCanvasSize();
        const debounce = (func, wait) => {
            let timeout;
            return function (...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func.apply(this, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        };

        this.debouncedUpdateCanvasSize = debounce(this.updateCanvasSize, 200);

        window.addEventListener('resize', this.debouncedUpdateCanvasSize, { passive: true });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.debouncedUpdateCanvasSize, { passive: true });
    }
}
</script>

<style scoped>
div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

canvas {
    flex: 1;
    border: 1px solid var(--sah-input-border-color);
    margin: 5px;
    box-shadow: var(--sah-box-shadow);
    border-radius: 5px;
    background-color: white;
    cursor: auto;
    max-height: 250px;
}

.flex-row {
    flex-direction: row;
}

#btn-wrap {
    flex-direction: column-reverse;
}

@media (min-width: 640px) {
    #btn-wrap {
        flex-direction: row;
    }
}

.scrollable-content p,
.pageLabel {
    font-size: 1rem !important;
}
</style>